import { useState} from 'react';
import { useNavigate } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import eye from "../../assets/eye.png"
import "../../assets/eyeStyle.css"
import { AccountCircle } from '@mui/icons-material';
import { useAuth0 } from '@auth0/auth0-react';

function HomePageBar() {
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const navigate = useNavigate();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogin = () => {
    if (!isAuthenticated) {
        return (
            <MenuItem onClick={() => loginWithRedirect()}>Sign in</MenuItem>
        )
    }
    else {
        return (
            <MenuItem onClick={() => logout()}>Sign out</MenuItem>
        )
    }
}

  return (
    <AppBar position="fixed" sx={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
        <Toolbar>
          <Typography
            component="a"
            href="/"

          >
            <img src={eye} alt="eye" className='eyeStyle'/>
          </Typography> 
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <MenuItem key="All stocks" onClick={() => {navigate('/stocks')}}>
              Markets
            </MenuItem>
            <MenuItem key="Twitter" onClick={() => window.location.href = 'https://twitter.com/aseallseeingeye'}>
              Twitter
            </MenuItem>
            <MenuItem key="Telegram" onClick={() => window.location.href = 'https://telegram.me/ase_otc_bot?start=deocto_main'}>
              Telegram
            </MenuItem>
          </Box>
          <Box  sx={{ flexGrow: 1, display: 'flex', justifyContent: 'right' }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <div>{handleLogin()}</div>
              </Menu>
          </Box>
        </Toolbar>
    </AppBar>
  );
}
export default HomePageBar;
