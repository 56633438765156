import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';

export const SearchBox = styled('form')({
    height: '50px',
    background: 'white',
    display: 'flex',
    borderRadius: '50px',
    overflow: 'hidden',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
});

export const SearchBoxInput = styled(TextField)({
    padding: '5px 20px',
    fontSize: '16px',
    borderRadius: '25px 0 0 25px',
    outline: 'none',
    width: '300px',
    '& .MuiInputBase-root': {
        padding: '1px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '& .MuiInputBase-input': {
        padding: '10px 20px',
    },
    '&:hover': {
        backgroundColor: '#e0e0e0', 
        transition: 'background-color 0.3s ease',
      },
});

export const SearchBoxButton = styled(IconButton)({
    backgroundColor: '#007bff',
    color: 'white',
    padding: '16px',
    fontSize: '16px',
    cursor: 'pointer',
    borderRadius: '0 25px 25px 0',
    '&:hover': {
        backgroundColor: '#0056b3',
    }
});
