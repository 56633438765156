import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Autocomplete,  } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useAuth0 } from '@auth0/auth0-react';
import { AccountCircle } from '@mui/icons-material';

import { SearchBoxInput, Search, SearchIconWrapper } from './NavBarStyles';
import eye from "../../assets/eye.png";
import useGetSymbolsOptions from '../../util/useGetSymbolsOptions';

export default function SearchAppBar() {
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [searchBarOpen, setSearchBarOpen] = useState<boolean>(false);
  const symbolsOptions = useGetSymbolsOptions(inputValue);
  const navigate = useNavigate();

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleMenuItemClick = (destination: string) => {
    navigate(destination);
    handleMenuClose();
  };

  const handleInputChange = (event: React.SyntheticEvent, newInputValue: string) => {
    setInputValue(newInputValue);
  };

  const handleOptionChange = (event: React.SyntheticEvent, newValue: string | null) => {
    if (newValue) {
        navigate(`/stocks/${newValue.trim()}`);
        setInputValue('');
    }
  };

  const handleProfileOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileClose = () => {
    setAnchorEl(null);
  };


  const handleLogin = () => {
    if (!isAuthenticated) {
        return (
            <MenuItem onClick={() => loginWithRedirect()}>Sign in</MenuItem>
        )
    }
    else {
        return (
            <MenuItem onClick={() => logout()}>Sign out</MenuItem>
        )
    }
  }

const handleSearchBarClick = () => {
  setSearchBarOpen(true);
};

const handleSearchBarBlur = () => {
  setInputValue('')
  setSearchBarOpen(false);
};



  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ background: 'linear-gradient(135deg, #360033, #1e4b56, #0b8793)' }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* Hamburger and Icon Box */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Menu
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem key="HomePage" onClick={() => handleMenuItemClick('/')}>
                  Home
                </MenuItem>
                <MenuItem key="All stocks" onClick={() => handleMenuItemClick('/stocks')}>
                  All Stocks
                </MenuItem>
                <MenuItem key="Twitter" onClick={() => window.location.href = 'https://twitter.com/aseallseeingeye'}>
                  Twitter
                </MenuItem>
                <MenuItem key="Telegram" onClick={() => window.location.href = 'https://telegram.me/ase_otc_bot?start=deocto_main'}>
                  Telegram
                </MenuItem>
              </Menu>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={handleMenuOpen}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                component="a"
                href="/"
                sx={{ marginLeft: 1 }}
              >
                <img src={eye} alt="eye" className='eyeStyle'/>
              </Typography> 
          </Box>
          {/* Search Box */}
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'right' }}>
            <Search 
              onClick={handleSearchBarClick}
              onBlur={handleSearchBarBlur}>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <Autocomplete
                freeSolo
                options={symbolsOptions}
                inputValue={inputValue}
                onInputChange={handleInputChange}
                onChange={handleOptionChange}
                renderInput={(params) => (
                  <SearchBoxInput
                    {...params}
                    variant="outlined"
                    placeholder={searchBarOpen ? 'Search...' : ''}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                    }}
                  />
                )}
              />
            </Search>
          </Box>
          {/* Profile Box */}
          <Box sx={{ flexGrow: 0, padding: 0 }}>
              <IconButton
                size="small"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleProfileOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleProfileClose}
              >
                <div>{handleLogin()}</div>
              </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

