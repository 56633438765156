import LimitedInformationLogo from '@mui/icons-material/ChangeHistoryRounded';
import NoInformationLogo from '@mui/icons-material/DangerousOutlined';

import styles from './TiersFilter.module.css';

interface TierFilterProps {
    tier: string;
  }
  

const TiersFilter: React.FC<TierFilterProps> = ({ tier }) => {
    if (tier === 'OTCQB') {
        return <div className={styles.logoOtcqx}>OTCQB</div>;
    } else if (tier === 'OTCQX International') {
        return <div className={styles.logoOtcqb}>OTCQX</div>;
    } else if (tier === 'Pink Current Information') {
        return <div className={styles.logoPink}>Pink</div>;
    } else if (tier === 'Pink Limited Information') {   
        return <LimitedInformationLogo style={{ color: 'red', transform: 'rotate(180deg)' }}/>;
    } else if (tier === 'Pink No Information') {
        return <NoInformationLogo style={{ color: 'red' }}/>;
    } else {
        return <div>Tier:</div>;
    }
}

export default TiersFilter;