import { useQuery } from "@tanstack/react-query";

import { fetchStocks } from "../../util/Http";
import { Stock } from "../../models/Stock";
import StockCard from "../UI/StockCard";
import LoadingOverlay from "../UI/LoadingOverlay";

const StocksPage: React.FC = () => {
    const { data, isLoading, isError } = useQuery<Stock[]>({
        queryKey: ["stocks"],
        queryFn: fetchStocks
      });


    if (isLoading) {
        return <LoadingOverlay />
    }
    
    if (isError) {
        return <div>Error</div>
    }
        
    return (
        <div style={{ background: 'black', color: 'white' }}>
            {data?.map((stock) => (
                <div key={stock.symbol}>
                    <StockCard stock={stock} />
                    <p>/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\</p>
                </div>
            ))}
        </div>
    );
}

export default StocksPage

