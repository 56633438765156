import { useEffect } from "react";

import HomePageBar from "../MUI/HomePageBar";
import Search from "../UI/Search"
import styles from './HomePage.module.css';
import { logEvent } from "../../util/Amplitude";
import { AmplitudeEventsEnum } from "../../models/AmplitudeEvents";

const HomePage: React.FC = () => {
  useEffect(() => {
    logEvent(AmplitudeEventsEnum.PAGE_VIEW, { page: 'Home' });
  }, []);

  return (
    <div className={styles.background}>
      <HomePageBar />
      <Search />
    </div>
  
  );
}

export default HomePage;