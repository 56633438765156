import VerifiedProfileIcon from '@mui/icons-material/CheckBoxOutlined';
import TransferAgentVerifiedIcon from '@mui/icons-material/GppGood';  
import IndependentDirectorsIcon from '@mui/icons-material/Groups';
import PennyStockExemptIcon from '@mui/icons-material/MonetizationOn';
import Logo12g32b from '@mui/icons-material/Public';
import { Box } from '@mui/material';

import styles from './Badges.module.css'

interface LogoFilterProps {
    verifiedProfile: boolean;
    transferAgentVerified: boolean;
    hasTwoIndDir: boolean;
    isPennyStockExempt: boolean;
    is12g32b: boolean;
    textStyle: any;
  }
  
const BadgesFilter: React.FC<LogoFilterProps> = (props) => {
    const badgesMapping: { prop: keyof LogoFilterProps; Icon: React.ElementType; text: string }[] = [
        { prop: 'verifiedProfile', Icon: VerifiedProfileIcon, text: 'Verified Profile' },
        { prop: 'transferAgentVerified', Icon: TransferAgentVerifiedIcon, text: 'Transfer Agent Verified' },
        { prop: 'hasTwoIndDir', Icon: IndependentDirectorsIcon, text: 'Independent Directors' },
        { prop: 'isPennyStockExempt', Icon: PennyStockExemptIcon, text: 'Penny Stock Exempt' },
        { prop: 'is12g32b', Icon: Logo12g32b, text: '12g3-2(b) Certified' },
      ];
      return (
        <Box className={styles.verifiedContainer}>
            {badgesMapping.map(({ prop, Icon, text }) => 
                props[prop] && (
                    <Box key={prop} className={styles.verifiedLine}>
                        <Icon style={{ color: '#2D9B1B' }} />
                        <p className={styles.verifiedText}>{text}</p>
                    </Box>
            )
            )}
        </Box>
      );
    };

export default BadgesFilter