import { useEffect } from "react";
import {
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";

import { QueryClientProvider } from "@tanstack/react-query";

import HomePage from './components/pages/HomePage';
import StocksPage from "./components/pages/StocksPage";
import StockPage from "./components/pages/StockPage";
import { queryClient } from "./util/Http";
import Layout from './components/Layout/Header';
import { initAmplitude } from "./util/Amplitude";


const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />, 
  },
  {
    path: '/',
    element: <Layout />, 
    children: [
      {
        path: 'stocks',
        element: <StocksPage />,
      },
      {
        path: 'stocks/:stock',
        element: <StockPage />,
      },
    ],
  },
]);

function App() {
  useEffect(() => {
    initAmplitude();
  }, []);
  
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
}

export default App;
