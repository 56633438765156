import { useEffect, useState } from "react";
import { Stock } from "../models/Stock";
import { fetchStockBySearchTerm } from "./Http";

export default function useGetSymbolsOptions (inputValue: string) {
    const [symbolsOptions, setSymbolsOptions] = useState<string[]>([]);

    useEffect(() => {
        const fetchSymbols = async () => {
            if (inputValue.length > 1) {
                const data = await fetchStockBySearchTerm(inputValue);
                const stockSymbols = data.map((stock: Stock) => stock.symbol);
                setSymbolsOptions(stockSymbols);
            } else {
                setSymbolsOptions([]);
            }
        };

        fetchSymbols();
    }, [inputValue]);

    return symbolsOptions;
};