import { alpha, styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

export const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  transition: 'width 0.3s ease', 
  marginRight: theme.spacing(1),
  marginLeft: 0,
  width: '35px',
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  '&:focus-within': {
    width: '150px',
  },
  [theme.breakpoints.up('sm')]: {
    marginRight: theme.spacing(2),
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    width: '150px',
    '&:focus-within': {
      width: '250px',
    },
  },
}));

export const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const SearchBoxInput = styled(TextField)(({ theme }) => ({
  width: 'calc(100% - 50px)',
  paddingLeft: `calc(1em + ${theme.spacing(4)})`,
  '& .MuiInputBase-root': {
      padding: '0',
  },
  '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
  },
  '& .MuiInputBase-input': {
      padding: '10px 20px',
  },
}));