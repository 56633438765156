import { useState, useEffect, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Autocomplete } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { SearchBox, SearchBoxInput, SearchBoxButton } from './SearchStyle';
import useGetSymbolsOptions from '../../util/useGetSymbolsOptions';


const Search: React.FC = () => {
    const [inputValue, setInputValue] = useState<string>('');
    const symbolsOptions = useGetSymbolsOptions(inputValue);
    const navigate = useNavigate();

    const handleOptionChange = (event: React.SyntheticEvent, newValue: string | null) => {
        if (newValue) {
            navigate(`/stocks/${newValue.trim().toLowerCase()}`);
            setInputValue(''); 
        }
    };

    const handleInputChange = (event: React.SyntheticEvent, newInputValue: string) => {
        setInputValue(newInputValue);
    };

    const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
    };

    return (
        <Box 
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: '35vh',
            }}
        >
            <SearchBox onSubmit={handleFormSubmit}>
                <Autocomplete
                    freeSolo
                    options={symbolsOptions}
                    inputValue={inputValue}
                    onInputChange={handleInputChange}
                    onChange={handleOptionChange}
                    renderInput={(params) => (
                        <SearchBoxInput
                            {...params}
                            variant="outlined"
                            placeholder="Search..."
                            InputProps={{
                                ...params.InputProps,
                                disableUnderline: true,
                            }}
                        />
                    )}
                />
                <SearchBoxButton 
                    type="submit" 
                    aria-label="search"
                >
                    <SearchIcon />
                </SearchBoxButton>
            </SearchBox>
        </Box>
    );
};

export default Search;
